import { SignedIn, SignedOut } from "@clerk/remix";

export function Header() {
	return (
		<header className="px-4 lg:px-6 h-14 flex items-center border-b">
			<div className="container mx-auto px-4 md:px-6 h-14 flex items-center">
				<a className="flex items-center justify-center" href="/">
					<img
						src="/logo.svg"
						alt="キリクリ（KIRICLI）サービスロゴ"
						className="h-8 w-auto"
					/>
				</a>
				<nav className="ml-auto flex gap-4">
					<SignedIn>
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="/home"
						>
							ホーム
						</a>
					</SignedIn>
					<SignedOut>
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="/sign-in"
						>
							ログイン
						</a>
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="/sign-up"
						>
							ベータ版登録
						</a>
					</SignedOut>
				</nav>
			</div>
		</header>
	);
}
