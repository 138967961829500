export function Footer() {
	return (
		<footer className="border-t">
			<div className="container mx-auto px-4 md:px-6 py-12">
				<div className="grid gap-8 md:grid-cols-4">
					<div>
						<h4 className="text-sm font-semibold mb-4 text-gray-700 dark:text-gray-200">
							サービス
						</h4>
						<ul className="space-y-2 text-sm text-gray-700 dark:text-gray-200">
							<li>
								<a className="hover:underline underline-offset-4" href="/about">
									キリクリについて
								</a>
							</li>
							<li>
								<a
									className="hover:underline underline-offset-4"
									href="https://www.sherringford.tech"
									target="_blank"
									rel="noreferrer"
								>
									運営会社
								</a>
							</li>
						</ul>
					</div>
					<div>
						<h4 className="text-sm font-semibold mb-4 text-gray-700 dark:text-gray-200">
							ガイド
						</h4>
						<ul className="space-y-2 text-sm text-gray-700 dark:text-gray-200">
							<li>
								<a href="/media" className="hover:underline">
									お役立ち記事
								</a>
							</li>
						</ul>
					</div>
					<div>
						<h4 className="text-sm font-semibold mb-4 text-gray-700 dark:text-gray-200">
							サポート
						</h4>
						<ul className="space-y-2 text-sm text-gray-700 dark:text-gray-200">
							<li>
								<a href="/terms" className="hover:underline">
									利用規約
								</a>
							</li>
							<li>
								<a href="/privacy" className="hover:underline">
									プライバシーポリシー
								</a>
							</li>
							<li>
								<a href="/tokushoho" className="hover:underline">
									特定商法取引法に基づく表記
								</a>
							</li>
							<li>
								<a
									href="https://forms.gle/cgEvjVmXpVHfeb476"
									target="_blank"
									rel="noreferrer"
									className="hover:underline"
								>
									お問い合わせ
								</a>
							</li>
						</ul>
					</div>
					<div>
						<h4 className="text-sm font-semibold mb-4 text-gray-700 dark:text-gray-200">
							公式アカウント
						</h4>
						<ul className="space-y-2 text-sm text-gray-700 dark:text-gray-200">
							<li>
								<a
									className="hover:underline flex items-center"
									href="https://x.com/kiricliApp"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/x-logo.png"
										alt="キリクリ公式Xアカウントへ"
										className="h-4 w-auto mr-2"
									/>
									X(旧Twitter)
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="border-t pt-6 mt-8 flex flex-col gap-2 sm:flex-row w-full shrink-0 items-center text-xs text-gray-500 dark:text-gray-400">
					<p>© 2024 Sherringford Co., Ltd</p>
				</div>
			</div>
		</footer>
	);
}
